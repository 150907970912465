import React from "react";
import {
  Typography,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { colors } from "../lib/theme";

const FormField = ({ component: Component, ...props }) => (
  <Component
    {...props}
    sx={{
      minWidth: props.minWidth || 120,
      fontSize: "1.2rem",
      color: colors.white,
      backgroundColor: 'transparent',
      '& .MuiSelect-icon': { color: colors.white },
      '& .MuiOutlinedInput-notchedOutline': { borderColor: colors.white },
      '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: colors.white },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: colors.white },
      '&.Mui-focused .MuiSelect-select, & .MuiSelect-select:focus': {
        color: colors.white,
        backgroundColor: "transparent",
      },
      ...(Component === TextField && {
        input: { color: colors.white, padding: "0.25rem 0.5rem" },
        '& .MuiOutlinedInput-root': {
          '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: colors.white },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: colors.white },
        },
        minWidth: props.minWidth || 300,
      }),
      ...(Component === Select && {
        '& .MuiSelect-select': {
          padding: "0.25rem 2rem 0.25rem 0.5rem",
          display: 'flex',
          alignItems: 'center',
        },
      }),
      ...props.sx
    }}
  />
);

const QuestionnaireForm = ({
  relationship,
  setRelationship,
  occasion,
  setOccasion,
  favoriteMemory,
  setFavoriteMemory,
  hobbiesInterests,
  setHobbiesInterests,
  relationshipOptions,
  occasionOptions,
}) => {
  const userCountry = localStorage.getItem('userCountry');

  return (
    <Typography
      sx={{ fontSize: "1.2rem", color: colors.white, mb: 2, lineHeight: 2.5 }}
    >
      I'm creating a special gift for{" "}
      <FormControl sx={{ display: "inline-flex", verticalAlign: "middle" }}>
        <FormField
          component={Select}
          value={relationship}
          onChange={(e) => setRelationship(e.target.value)}
          displayEmpty
        >
          <MenuItem value="" disabled>
            Select
          </MenuItem>
          {relationshipOptions.map((option) => (
            <MenuItem key={option.us} value={option.us}>
              {userCountry === "UK" ? option.uk : option.us}
            </MenuItem>
          ))}
        </FormField>
      </FormControl>{" "}
      for their{" "}
      <FormControl sx={{ display: "inline-flex", verticalAlign: "middle" }}>
        <FormField
          component={Select}
          value={occasion}
          onChange={(e) => setOccasion(e.target.value)}
          displayEmpty
        >
          <MenuItem value="" disabled>
            Select
          </MenuItem>
          {occasionOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </FormField>
      </FormControl>
      . One of my lasting memories with them is{" "}
      <FormField
        component={TextField}
        value={favoriteMemory}
        onChange={(e) => setFavoriteMemory(e.target.value)}
        variant="outlined"
        size="small"
        sx={{ verticalAlign: "middle" }}
        placeholder="e.g. the time we went to Paris together"
      />
      , and I know they love{" "}
      <FormField
        component={TextField}
        value={hobbiesInterests}
        onChange={(e) => setHobbiesInterests(e.target.value)}
        variant="outlined"
        size="small"
        sx={{ verticalAlign: "middle" }}
        placeholder="e.g. reading, cooking, gardening, etc."
      />
      .
    </Typography>
  );
}

export default QuestionnaireForm;
